import * as React from 'react';
import PrivacyPolicy from '../components/privacypolicy/privacypolicy';
import Layout from '../components/layout/layout';

function PrivacyPage({ location }) {
  const headerContent = {};
  return (
    <Layout
      title="Privacy Policy | HIVE"
      headerContent={headerContent}
      location={location}
      emptyHeader
    >
      <PrivacyPolicy />
    </Layout>
  );
}

export default PrivacyPage;
