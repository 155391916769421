/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';

function PrivacyPolicy() {
  return (
    <div
      className="
      xl:w-[1200px]
      lg:w-[980px]
      mobile:w-full
      mobile:px-[15px]
      mx-auto
    "
    >
      <div className="lg:mx-10 mt-20">
        <div>
          <h1 className="text-primary text-[40px] font-bold">Privacy Policy</h1>

          <div className="mt-4 pl-0  md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              This notice provides the Program Executive Office, Defense Healthcare
              Management Systems (PEO DHMS) privacy policy regarding the nature,
              purpose, use, and sharing of any Personally Identifiable Information
              (PII) collected via this website. Our privacy policy explains our
              information practices when you provide PII to us, whether collected
              online or offline, or when you visit us online to browse, obtain
              information, or conduct a transaction. PII may include: your name,
              email, mailing and/or home address, phone numbers, or other information
              that identifies you personally. We do require you to register to join
              the community conversations and groups, but anonymous registration is
              allowed.
            </p>

            <p className="mt-8 max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              The PII you provide on this website will be used for direct
              communication to you, and may be publicly listed within our Users Lists
              on the Hive Community. We will protect your information consistent with
              the principles of the
              <span className="text-primary">
                {' '}
                <a
                  className="no-underline"
                  href="https://www.state.gov/system-of-records-notices-privacy-office/"
                >
                  Privacy Act of 1974
                </a>
              </span>
              , the{' '}
              <span className="text-primary">
                <a
                  href="https://www.justice.gov/opcl/e-government-act-2002"
                  className="no-underline>"
                >
                  {' '}
                  E-Government Act of 2002
                </a>
              </span>
              , and the{' '}
              <span className="text-primary">
                {' '}
                <a
                  className="no-underline"
                  href="https://www.archives.gov/about/laws/fed-agencies.html"
                >
                  Federal Records Act
                </a>
              </span>
              .
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-primary text-[32px] font-bold">
            Personally Identifiable Information
          </h1>
          <div className="mt-4 pl-0 md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              As a general rule, PEO DHMS does not collect PII about you when you
              visit our website, unless you choose to provide such information to us.
              Submitting PII through our website is voluntary. By doing so, you are
              giving the PEO DHMS your permission to use the information for the
              stated purpose. However, not providing certain information may result
              in an inability to provide you with the service you desire.
            </p>
            <p className="mt-8 max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              If you choose to provide us with PII on a this website, through such
              methods as completing a web form or sending us an email, we will use
              that information to help us provide you the information or service you
              have requested or to respond to your message. The information we may
              receive from you varies based on what you do when visiting our site.
            </p>
            <p className="mt-8  max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              Generally, the information requested by PEO DHMS will be used to
              respond to your inquiry, provide you with updates, or open direct
              contact channels between you, PEO DHMS, and its partners. PEO DHMS will
              not provide you information to be used for purposes unrelated to the
              intent described above.
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-primary text-[32px] font-bold">Email</h1>
          <div className="mt-4 pl-0 md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              This site will allow you to send us an email. We will use the
              information you provide to respond to your inquiry. We will only send
              you general information via email. You should be reminded that email
              may not necessarily be secure against interception. Therefore, we
              insist that you do not send sensitive personal data (such as your
              Social Security number) or personal health information (PHI) to us via
              email. If your intended email communication is very sensitive, or
              includes information such as your bank account, credit card, or Social
              Security number, you should instead send it by U.S. mail. Another
              alternative may be submission of data through a secure web page, if
              available.
            </p>
            <p className="mt-8 max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              Electronic mail messages that meet the definition of records in the{' '}
              <span className="text-primary">
                <a
                  className="no-underline"
                  href="https://www.archives.gov/about/laws/fed-agencies.html"
                >
                  Federal Records Act
                </a>
              </span>
              (44 U.S.C. 3101 et seq.) are covered under the same disposition
              schedule as all other Federal records. This means that emails you send
              us will be preserved and maintained for varying periods of time if
              those emails meet the definition of Federal records. Electronic
              messages that are not records are deleted when no longer needed.
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-primary text-[32px] font-bold">
            Automatically Collected Information
          </h1>
          <div className="mt-4 pl-0 md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              We collect and temporarily store certain information about your visit
              for use in site management and security purposes only. We collect and
              analyze this information because it helps us to better design our
              website to suit your needs. We may also automatically collect
              information about the web content you view in the event of a known
              security or virus threat. This information includes:
            </p>
            <div className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              <ol className="ml-6 list-decimal ">
                <li>
                  The Internet domain from which you access our website (for example,
                  “xcompany.com” if you use a private Internet access account, or
                  “yourschool.edu” if you connect from an educational domain);
                </li>
                <li>
                  The Internet Protocol (IP) address (a unique number for each
                  computer connected to the Internet) from which you access our
                  website;
                </li>
                <li>
                  The type of browser (e.g., Firefox, Internet Explorer, Chrome) used
                  to access our site;
                </li>
                <li>
                  The operating system (e.g., Windows, Mac OS, Unix) used to access
                  our site;
                </li>
                <li>The date and time you access our site;</li>
                <li>
                  The Universal Resource Locators (URLs), or addresses, of the pages
                  you visit;
                </li>
                <li>Your username, if it was used to log in to the website; and</li>
                <li>
                  If you visited this website from another website, the URL of the
                  forwarding site.
                </li>
              </ol>
            </div>
            <p className="mt-8 max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              We may share the above information with our employees or
              representatives with a “need-to-know” in the performance of their
              official duties, other Federal agencies, or other named representatives
              as needed to quickly process your request or transaction. This
              information is only used to help us make our site more useful for you.
              Raw data logs are retained temporarily as required for security and
              site management purposes only. More information about how we share
              information can be found in our Privacy Act Systems of Records Notices.
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-primary text-[32px] font-bold">
            Information Collected for Tracking and Customization (Cookies)
          </h1>
          <div className="mt-4 pl-0 md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              A cookie is a small file that a website transfers to your computer to
              allow it to remember specific information about your session while you
              are connected. Your computer will only share the information in the
              cookie with the website that provided it, and no other website can
              request it. There are two types of cookies:
            </p>
            <ul className="ml-6 list-disc max-w-[1075px]  text-justify mobile:text-left text-neutral-400 leading-loose">
              <li>
                Session: Session cookies last only as long as your web browser is
                open. Once you close your browser, the cookie is deleted. Websites
                may use session cookies for technical purposes such as to enable
                better navigation through the site, or to allow you to customize your
                preferences for interacting with the site.
              </li>
              <li>
                Persistent: Persistent cookies are saved on a user’s hard drive in
                order to determine which users are new to the site or are returning,
                and for repeat visitors, to block recurring invitations to take the
                ForeSee satisfaction survey.
              </li>
            </ul>

            <p className="mt-8 max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              If you do not wish to have session or persistent cookies stored on your
              machine, you can turn cookies off in your browser. You will still have
              access to all information and resources at PEO DHMS websites. However,
              turning off cookies may affect the functioning of some PEO DHMS
              websites. Be aware that disabling cookies in your browser will affect
              cookie usage at all other websites you visit as well.
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-primary text-[32px] font-bold">Security</h1>
          <div className="mt-4 pl-0 md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              PEO DHMS takes the security of all PII very seriously. We take
              precautions to maintain the security, confidentiality, and integrity of
              the information we collect at this site. Such measures include access
              controls designed to limit access to the information to the extent
              necessary to accomplish our mission. We also employ various security
              technologies to protect the information stored on our systems. We
              routinely test our security measures to ensure that they remain
              operational and effective.
            </p>
            <div className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose mt-8">
              We take the following steps to secure the information we collect:
              <ul className="list-disc ml-7">
                <li>
                  Employ internal access controls to ensure that only personnel who
                  have access to your information are those with a need to do so to
                  perform their official duties.
                </li>
                <li>
                  Train appropriate personnel on our privacy and security policies
                  and compliance requirements.
                </li>
                <li>
                  Perform regular backups of the information we collect online to
                  ensure against loss.
                </li>
                <li>
                  Use technical controls to secure the information we collect online
                  including, but not limited to:
                  <ul className="list-disc ml-7">
                    <li>Secure Socket Layer (SSL)</li>
                    <li>Encryption</li>
                    <li>Firewalls</li>
                    <li>Password protections</li>
                  </ul>
                </li>
                <li>
                  Periodically test our securityy procedures to ensure personnel and
                  technical compliance.
                </li>
              </ul>
            </div>
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose mt-8">
              We hold our contractors and other third-party providers to the same
              high standards that we use to ensure the security, confidentiality, and
              integrity of personal information they may have access to in the course
              of their work completed on behalf of PEO DHMS.
            </p>
          </div>
        </div>

        <div className="mt-16 mb-32">
          <h1 className="text-primary text-[32px] font-bold">
            Visiting Other Websites
          </h1>
          <div className="mt-4 pl-0 md:pl-6">
            <p className="max-w-[1100px] text-justify mobile:text-left text-neutral-400 leading-loose">
              Our website contains links to international agencies, private
              organizations, and some commercial entities. These websites are not
              within our control and may not follow the same privacy, security, or
              accessibility policies. Once you link to another site, you are subject
              to the policies of that site. All Federal websites, however, are
              subject to the same Federal policy, security, and accessibility
              mandates. Additional information is available in PEO DHMS’s{' '}
              <span className="text-primary">
                <a
                  className=" no-underline"
                  href="https://www.state.gov/external-link-policy-and-disclaimers/"
                >
                  External Link Policy and Disclaimers
                </a>
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
